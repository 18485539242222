import React, { useEffect, useRef, useState } from 'react';

export const paymentterms = 30;

export const toastOptions = {
    isLoading: false,
    autoClose: 5000,
    hideProgressBar: false,
    draggable: true,
    closeOnClick: true,
}

export const DateOptions = {

    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "yyyy-mm-dd",
    enableTime: false,
    required: true,
    allowInput: true,
}

export const daysoverdue_str = (outStandingAmount, due_date) => {

    let overduestr = outStandingAmount > 0 ? (Math.abs(daysoverdue(due_date)) == 0) ? "Invoice Due Today" : (daysoverdue(due_date) > 0) ? "" + daysoverdue(due_date) + " days overdue" : "Invoice Due In " + Math.abs(daysoverdue(due_date)) + " days" : "";
    return overduestr;
}

export const dateDiffDays = (date1, date2) => {

    date1 = new Date(date1);
    date2 = new Date(date2)

    let days = Math.floor((date1 - date2) / (24 * 3600 * 1000));

    return days;

}

export const daysoverdue = (due_date, inc_str = false) => {

    let today = new Date();
    let duedate = new Date(due_date)
    let str;
    let days = Math.floor((today - duedate) / (24 * 3600 * 1000));

    if (inc_str) {
        if (days > 0) {
            return days + " overdue"
        }
        else {
            return days + " underdue"
        }
    }
    return days;

}

export const getTax = (amount, tax_rate, addorsubtract) => {
    if (addorsubtract == "add") {
        return (amount * (tax_rate / 100));
    }

}

export const quoteitemstotal = (items) => {
    return items?.reduce((total, item) => (
        !isNaN(item.price) ? (total += (Number(item.price) * (item.qty) ? Number(item.qty) : 1))
            : total
    ), 0)
}

export const ControlledInput = (props) => {
    const { value, onChange, ...rest } = props;
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = (e) => {
        setCursor(e.target.selectionStart);
        onChange && onChange(e);
    };

    return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

export const currencyFormat = (num, thistype = "") => {

    if (num === "" || typeof (num) === "undefined" || num == "NaN") {
        num = 0;
    }
    if (typeof (num) === "string") {
        num = num.replace('$', '').replace(',', '');
    }

    var newmoney = parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    if (thistype === "numbers") {
        newmoney = newmoney.replace('$', '').replace(',', '');
        // newmoney = parseFloat(newmoney);f
    }
    else {
        newmoney = '$' + newmoney;
    }

    return newmoney;

}

export const addDays = (date, repeat_days) => {
    return new Date(new Date(date).getTime() + (repeat_days * 24 * 60 * 60 * 1000));
}

export const isMyProjectTask = (taskorProject, userid) => {

    let project = taskorProject;


    if (taskorProject.cards) {
        //is a project
        project = taskorProject;
    } else {
        //is a task
        project = { cards: [{ tasks: [taskorProject] }] }
    }

    let isusertask = project.cards?.filter(card => card.tasks?.filter(task => task.users?.filter(user => user._id == userid))).length > 0 ? true : false;

    return (project.account_manager == userid || project.creator?._id == userid || isusertask) ? true : false;
}

export const convertminstohoursmins = (totalMins) => {

    totalMins = isNaN(totalMins) ? 0 : totalMins;

    var hours = Math.floor(totalMins / 60);
    var minutes = totalMins % 60;

    let actHoursStr = hours + "h " + minutes + "mins"

    return { str: actHoursStr, h: hours, m: minutes }
}

export const getHoursMinsStr = (projectorcardortask, invoiceable) => {


    let project = {};

    if (projectorcardortask.timesheet) { // has a timesheet is a task 
        project = { ...projectorcardortask, cards: [{ tasks: [{ ...projectorcardortask }] }] }
    }

    if (projectorcardortask.cards) { // has cards is a project
        project = projectorcardortask;
    }

    let totalMins = project?.cards?.reduce((totalMins, card) => (
        totalMins += card.tasks?.reduce((totalMins, task) => (
            invoiceable && task.invoiceable || !invoiceable ?
                totalMins += task.timesheet?.reduce((totalMins, timesheet) => (
                    totalMins += (parseFloat(timesheet.hours) * 60) + parseFloat(timesheet.mins)
                ), 0) : 0
        ), 0)
    ), 0);

    totalMins = isNaN(totalMins) ? 0 : totalMins;

    var hours = Math.floor(totalMins / 60);
    var minutes = totalMins % 60;

    let actHoursStr = hours + "h " + minutes + "mins"

    return { str: actHoursStr, h: hours, m: minutes, totalmins: totalMins }

}

export const dateFormat = (thisdate, thistype = "") => {

    if (!thisdate) {
        return "";
    }
    if (thisdate) {
        var thistime;

        if (typeof (thisdate) == "string") {
            if (thisdate.indexOf("T") > 0) {
                let thisdateparts = thisdate.split("T");

                thisdate = thisdateparts[0];
                thistime = thisdateparts[1];
                thistime = thistime.split(".");
                thistime = thistime[0]
            }
        }

        var date = new Date(thisdate);
    }

    if (date instanceof Date && !isNaN(date.valueOf())) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const monthnumber = ("0" + parseInt(date.getMonth() + 1)).slice(-2);


        if (thistime = "00:00:00") {
            thistime = "";
        }
        if (thistype == "nice") {
            var newdate = date.getDate() + ' ' + (monthNames[date.getMonth()]) + ' ' + date.getFullYear() + " " + thistime;
        }
        else if (thistype = "yyyy-mm-dd") {

            var newdate = date.getFullYear() + '-' + monthnumber + '-' + ("0" + date.getDate()).slice(-2);

        }
        else if (thistype = "dd-mm-yyyy") {
            var newdate = ("0" + date.getDate()).slice(-2) + '-' + monthnumber + '-' + date.getFullYear();
        }

        else if (thistype = "Y-m-d H:i:s") {
            var newdate = (date.getFullYear() + "-" + monthnumber + "-" + "0" + date.getDate()).slice(-2) + ' ' + date.getHours() + ':' + date.getMinutes() + ":" + date.getSeconds();
        }

        else {
            var newdate = date.getDate() + ' ' + (monthNames[date.getMonth()]) + ' ' + date.getFullYear();
        }

        return newdate;
    }
    else {

        return "";
    }
}

export const DateDiff = (date1, date2) => {
    var datediff = date1.getTime() - date2.getTime(); //store the getTime diff - or +
    return (datediff / (24 * 60 * 60 * 1000)); //Convert values to -/+ days and return value      
}
export const sort = (data, sortKey, desc) => {

    for (let i in data) {
        for (let j in data) {
            if (desc) {
                if (data[i][sortKey] > data[j][sortKey]) {
                    let fcus = data[i]
                    data[i] = data[j]
                    data[j] = fcus
                }
            } else {
                if (data[i][sortKey] < data[j][sortKey]) {
                    let fcus = data[j]
                    data[j] = data[i]
                    data[i] = fcus
                }
            }
        }
    }
    return data
}

export const tillTimeString = (date) => {
    if (!date) {
        return "";
    }
    date = new Date(date)
    let now = new Date()
    let diff = now - date
    let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
    let months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
    let days = Math.floor(diff / (1000 * 60 * 60 * 24))
    let hours = Math.floor(diff / (1000 * 60 * 60))
    let minutes = Math.floor(diff / (1000 * 60))
    let seconds = Math.floor(diff / 1000)

    let ds = date.toDateString().substring(4) + ', ' + date.toTimeString().substring(0, 5)
    if (years > 0) {
        return `${ds} (${years} year${years > 1 ? 's' : ''} ago)`
    } else if (months > 0) {
        return `${ds} (${months} month${months > 1 ? 's' : ''} ago)`
    } else if (days > 0) {
        return `${ds} (${days} day${days > 1 ? 's' : ''} ago)`
    } else if (hours > 0) {
        return `${ds} (${hours} hour${hours > 1 ? 's' : ''} ago)`
    } else if (minutes > 0) {
        return `${ds} (${minutes} minute${minutes > 1 ? 's' : ''} ago)`
    } else if (seconds > 0) {
        return `${ds} (${seconds} second${seconds > 1 ? 's' : ''} ago)`
    } else {
        return 'Just now'
    }
}

export const isValidEmail = (email) => {

    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}