import { useState, useContext, useRef } from "react"
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';

export default function CardHeader({ card })
{
  const { projects, activeProject } = useContext(ProjectsContext); 
  const { dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);

  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(card.name);
  const inputRef = useRef(null);

  function handleNameChange(newName) 
  { 
  card.name = newName;
    const cards = structuredClone(activeProject.cards).map(c => 
    {
      if (c._id === card._id)
        c.name = newName;

      return c;
    })

    dispatch({type:"updateActiveProject", payload:{...newActiveProject,cards}})

    axios.post('/a/project/'+activeProject._id+'/cards/'+card._id+'/update', 
    { 
      type: "name",
      cardName: newName 
    })
    .catch(err => 
    {
      setResponseError(err, dispatch)
      dispatch({type:"updateActiveProject", payload:activeProject})
    })

    dispatch({ type: 'confirmationShown', payload: false })
  }

  function handleSave() 
  {
    setEditing(false);

    if (inputRef.current.value !== card.name)
      handleNameChange(inputValue);
  }

  function handleKeyDown(e)
  {
    if (e.key === "Enter")
      handleSave();
  }

  return (
    <div className='card__header'>
    {
      editing 
      ? <input 
          autoFocus 
          type="text" 
          value={ inputValue } 
          ref={ inputRef } 
          style={ {width: '100%'} } 
          onChange={ e => setInputValue(e.target.value) } 
          onBlur={ handleSave } 
          onKeyDown={ handleKeyDown }
        />

      : <div onClick={ () => {setEditing(true)} }>{ card.name }</div>
    }
    </div>
  )
}