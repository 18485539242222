import React, { useState, useContext, useRef } from "react";
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function AddTask({ card }) {

  const { projects, activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);

  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputValueRef = useRef();

  const tasksFiltered = card.tasks || [];

  console.log("ADDTASK CARD: ", card);

  function saveTask(content) {

    const taskList = structuredClone(card.tasks) ?? [];
    const now = Date.now();

    console.log("saving task to card: ", card);

    let newTask = {
      card: card._id,
      creator: state.user._id,
      position: taskList.length + 1,
      content: content,
      name: content,
      project: activeProject._id,
      tags: [],
      resources: [],
      created_at: now,
      updated_at: now
    }

    const cards = newActiveProject.cards.map(c => {
      if (c._id === card._id) {
        let ctasks = c.tasks ? c.tasks : [];
        ctasks.push(newTask);
        c.tasks = ctasks;
      }

      return c;
    })

    dispatch({ type: "updateActiveProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/project/' + activeProject._id + '/cards/' + card._id + '/tasks/create', { newTask: newTask })
      .then(res => {

        newTask._id = res.data.newTask._id;

        const cards = newActiveProject.cards.map((c, index) => {
          c.tasks.map((t, index) => {
            if (t.created_at == now) {
              t = res.data.newTask
            }
          })
          return c;
        })
        dispatch({ type: "updateActiveProject", payload: { ...newActiveProject, cards } });
        dispatch({ type: "setActiveProjectTaskID", payload: newTask._id })
      })
      .catch(err => {

        setResponseError(err, dispatch);
        dispatch({ type: "updateActiveProject", payload: activeProject })
      })

    setInputValue('');
  }

  async function handleSave() {
    if (inputValueRef.current.value !== '')
      await saveTask(inputValue);

    setEditing(false);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave();

    else if (e.key === "Escape") {
      setEditing(false);
      setInputValue('');
    }
  }

  function handleInputGrowth() {
    const textArea = document.getElementById('text-area');

    textArea.addEventListener('input', () => {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    });
  }

  return (
    <div className="card__add-task">
      {
        editing
          ? <>
            Task Name<br /><input
              type="text"
              style={{ width: '100%', overflow: 'hidden' }}
              id='text-area'
              ref={inputValueRef}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              onInput={handleInputGrowth}
              autoFocus
            />

          </>
          : <button onClick={() => { setEditing(true) }}><FontAwesomeIcon icon={faPlus} /><span> Add task</span></button>
      }
    </div>
  )
}