import React, { useState, useContext, useEffect } from 'react';
import { ProjectsContext, ReducerContext } from 'app';
import axios, { setResponseError } from 'utils/axiosConfig';

import { ChromePicker } from 'react-color';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Project } from '../../../project/project';
import Popup from '../../../../popup/popup';


export function HeaderColor() {
  const { activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [newColor, setNewColor] = useState(activeProject.color);

  const [headerState, setHeaderState] = React.useState(null);
  let newActiveProject = activeProject;

  useEffect(() => { setNewColor(activeProject.color) }, [activeProject])

  function toggleColorPicker() {
    setHeaderState({ ...headerState, pickerActive: !headerState.pickerActive })
    const oldColor = activeProject.color;

    if (!headerState.pickerActive || newColor === oldColor)
      return;

    newActiveProject.color = newColor;
    newActiveProject.updated_at = Date.now();
    dispatch({ type: 'updateActiveProject', payload: newActiveProject })

    axios.post('/a/project/' + activeProject._id + '/update?type=color',
      {
        newColor: newColor
      })
      .catch(err => {
        setResponseError(err, dispatch)

        dispatch({ type: 'updateActiveProject', payload: activeProject })

      })
  }


  function openProjectWindow() {
    dispatch({ type: "openProjectWindow", payload: activeProject })
  }

  return (
    <>
      <div className='taskbar__info' onClick={openProjectWindow} >
        <FontAwesomeIcon icon={faFileInvoice} />
      </div>
      <div className='taskbar__color' onClick={toggleColorPicker} style={{ color: newColor }}>
        <FontAwesomeIcon icon={faSquare} />
      </div>
      {state?.openProject &&
        <>
          <Popup hide={() => { dispatch({ type: "openProjectWindow", payload: null }) }}>
            <Project project={state?.openProject} />
          </Popup>
        </>
      }
      {
        headerState?.pickerActive &&
        <div>
          <div onClick={toggleColorPicker} className='chrome-picker__bg' />
          <ChromePicker color={newColor} onChange={color => { setNewColor(color.hex) }} />
        </div>
      }
    </>
  )
}

export function HeaderTitle() {
  const { activeProject } = useContext(ProjectsContext);
  const { dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);

  const [editing, setEditing] = useState(false);

  if (activeProject._id !== newActiveProject._id) {
    setNewActiveProject(activeProject);
  }

  function handleNameChange() {
    let newName = newActiveProject.name;
    const oldName = activeProject.name;

    if (newName === oldName)
      return;

    dispatch({ type: 'updateActiveProject', payload: newActiveProject })

    axios.post('/a/project/' + activeProject._id + '/update?type=name',
      {
        newName: newActiveProject.name
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: 'updateActiveProject', payload: activeProject })

      })
  }

  function handleSave() {
    setEditing(false);
    handleNameChange();
  }

  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave();
  }

  return (
    <div className='taskbar__text'>
      {
        editing
          ? <input
            autoFocus
            type="text"
            value={newActiveProject.name}
            style={{ width: '100%' }}
            onChange={e => setNewActiveProject({ ...newActiveProject, name: e.target.value })}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
          />

          : <><h1 style={{ width: '100%' }} onClick={() => { setEditing(true) }}>{activeProject.name}</h1>

          </>

      }
    </div>
  );
}