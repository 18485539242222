export function Loading()
{
  return (
    <svg className="loadingsvg" width="1920" height="923" preserveAspectRatio="xMidYMid" viewBox="0 0 1920 923">
    <g transform="translate(960,461.5) scale(1,1) translate(-960,-461.5)"><g transform="translate(-100 790.1536236695742) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 790.1536236695742;1920 790.1536236695742" dur="66.66666666666667s" repeatCount="indefinite" begin="-36.329687640337575s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 306.37757959570837) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 306.37757959570837;1920 306.37757959570837" dur="66.66666666666667s" repeatCount="indefinite" begin="-0.11977755094097672s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 801.2108170968743) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 801.2108170968743;1920 801.2108170968743" dur="66.66666666666667s" repeatCount="indefinite" begin="-50.652582672749645s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 236.57648739990822) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 236.57648739990822;1920 236.57648739990822" dur="66.66666666666667s" repeatCount="indefinite" begin="-5.455310081202688s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 738.5489248313243) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 738.5489248313243;1920 738.5489248313243" dur="66.66666666666667s" repeatCount="indefinite" begin="-35.91036699753696s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 862.3796162482698) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 862.3796162482698;1920 862.3796162482698" dur="66.66666666666667s" repeatCount="indefinite" begin="-14.536624145204515s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 299.9540468356065) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 299.9540468356065;1920 299.9540468356065" dur="66.66666666666667s" repeatCount="indefinite" begin="-3.9877910044673857s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 239.6842509859901) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 239.6842509859901;1920 239.6842509859901" dur="66.66666666666667s" repeatCount="indefinite" begin="-46.86855956824613s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 167.82981138105495) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 167.82981138105495;1920 167.82981138105495" dur="66.66666666666667s" repeatCount="indefinite" begin="-6.239880135314439s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 350.8235477522312) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 350.8235477522312;1920 350.8235477522312" dur="66.66666666666667s" repeatCount="indefinite" begin="-55.203730471489635s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 131.22969897599592) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 131.22969897599592;1920 131.22969897599592" dur="66.66666666666667s" repeatCount="indefinite" begin="-30.12766455477755s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(0.55)"></path>
    </g><g transform="translate(-100 859.5456541013292) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 859.5456541013292;1920 859.5456541013292" dur="33.333333333333336s" repeatCount="indefinite" begin="-66.07664551834971s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 618.3776181939594) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 618.3776181939594;1920 618.3776181939594" dur="33.333333333333336s" repeatCount="indefinite" begin="-37.28105050432455s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 824.1575620687107) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 824.1575620687107;1920 824.1575620687107" dur="33.333333333333336s" repeatCount="indefinite" begin="-44.7982506762464s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 670.8190100829024) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 670.8190100829024;1920 670.8190100829024" dur="33.333333333333336s" repeatCount="indefinite" begin="-6.148021644967742s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 798.9695508757518) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 798.9695508757518;1920 798.9695508757518" dur="33.333333333333336s" repeatCount="indefinite" begin="-45.53445193539839s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 180.93558706698283) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 180.93558706698283;1920 180.93558706698283" dur="33.333333333333336s" repeatCount="indefinite" begin="-54.86692322396067s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 630.605193955567) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 630.605193955567;1920 630.605193955567" dur="33.333333333333336s" repeatCount="indefinite" begin="-32.37850293560987s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 638.0450561185608) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 638.0450561185608;1920 638.0450561185608" dur="33.333333333333336s" repeatCount="indefinite" begin="-37.76936730769859s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 252.08217398824905) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 252.08217398824905;1920 252.08217398824905" dur="33.333333333333336s" repeatCount="indefinite" begin="-30.36054563086051s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 587.1578426218052) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 587.1578426218052;1920 587.1578426218052" dur="33.333333333333336s" repeatCount="indefinite" begin="-60.094321720642654s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 882.673759614962) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 882.673759614962;1920 882.673759614962" dur="33.333333333333336s" repeatCount="indefinite" begin="-37.89194207602077s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#ffffff" transform="scale(0.7)"></path>
    </g><g transform="translate(-100 918.714969923401) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 918.714969923401;1920 918.714969923401" dur="22.222222222222225s" repeatCount="indefinite" begin="-17.954149781879963s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 463.0410049303359) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 463.0410049303359;1920 463.0410049303359" dur="22.222222222222225s" repeatCount="indefinite" begin="-56.66687132810392s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 901.4274850679762) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 901.4274850679762;1920 901.4274850679762" dur="22.222222222222225s" repeatCount="indefinite" begin="-9.813724504028798s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 816.2700046288047) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 816.2700046288047;1920 816.2700046288047" dur="22.222222222222225s" repeatCount="indefinite" begin="-1.0990559159140527s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 143.79308620667013) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 143.79308620667013;1920 143.79308620667013" dur="22.222222222222225s" repeatCount="indefinite" begin="-52.38909482829056s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 694.3704747319293) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 694.3704747319293;1920 694.3704747319293" dur="22.222222222222225s" repeatCount="indefinite" begin="-12.403507708473368s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 586.3544319468206) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 586.3544319468206;1920 586.3544319468206" dur="22.222222222222225s" repeatCount="indefinite" begin="-5.502004017064592s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 327.6652458438947) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 327.6652458438947;1920 327.6652458438947" dur="22.222222222222225s" repeatCount="indefinite" begin="-22.682420436130165s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 168.56695388275122) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 168.56695388275122;1920 168.56695388275122" dur="22.222222222222225s" repeatCount="indefinite" begin="-41.70443464181529s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 737.7572276109415) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 737.7572276109415;1920 737.7572276109415" dur="22.222222222222225s" repeatCount="indefinite" begin="-62.291753519626575s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 857.8509190787086) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 857.8509190787086;1920 857.8509190787086" dur="22.222222222222225s" repeatCount="indefinite" begin="-48.8011962371147s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#f1f2f3" transform="scale(0.85)"></path>
    </g><g transform="translate(-100 880.6530456715421) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 880.6530456715421;1920 880.6530456715421" dur="16.666666666666668s" repeatCount="indefinite" begin="-3.6394451141105253s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 211.31621332294884) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 211.31621332294884;1920 211.31621332294884" dur="16.666666666666668s" repeatCount="indefinite" begin="-31.481761460147787s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 110.09143279090252) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 110.09143279090252;1920 110.09143279090252" dur="16.666666666666668s" repeatCount="indefinite" begin="-34.54577617224445s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 726.9694874198492) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 726.9694874198492;1920 726.9694874198492" dur="16.666666666666668s" repeatCount="indefinite" begin="-39.1047343111322s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 541.2370808182832) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 541.2370808182832;1920 541.2370808182832" dur="16.666666666666668s" repeatCount="indefinite" begin="-15.575631241118975s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 730.934058957101) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 730.934058957101;1920 730.934058957101" dur="16.666666666666668s" repeatCount="indefinite" begin="-18.01609363369668s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 429.88623668440704) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 429.88623668440704;1920 429.88623668440704" dur="16.666666666666668s" repeatCount="indefinite" begin="-7.115411050070143s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 750.9614953672275) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 750.9614953672275;1920 750.9614953672275" dur="16.666666666666668s" repeatCount="indefinite" begin="-12.987003351225143s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 483.8544824859523) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 483.8544824859523;1920 483.8544824859523" dur="16.666666666666668s" repeatCount="indefinite" begin="-15.668239759287433s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 900.3190904091614) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 900.3190904091614;1920 900.3190904091614" dur="16.666666666666668s" repeatCount="indefinite" begin="-39.43491884036815s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 390.20003717060956) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 390.20003717060956;1920 390.20003717060956" dur="16.666666666666668s" repeatCount="indefinite" begin="-58.20089603860382s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g><g transform="translate(-100 84.90864977021926) rotate(0)">
    <animateTransform attributeName="transform" type="translate" keyTimes="0;1" values="-100 84.90864977021926;1920 84.90864977021926" dur="16.666666666666668s" repeatCount="indefinite" begin="-34.50057784272557s"></animateTransform>
    <path d="M84.717,33.597c0.791-2.503,1.186-5.138,1.186-7.773C85.903,11.594,74.308,0,60.079,0 c-9.881,0-18.445,5.534-22.793,13.702c-1.581-0.527-3.426-0.791-5.138-0.791c-9.486,0-17.128,7.642-17.128,17.128 c0,1.186,0.132,2.372,0.395,3.426C6.719,34.783,0,42.424,0,51.515C0,61.66,8.169,69.829,18.314,69.829h63.373 C91.831,69.829,100,61.66,100,51.515C99.868,42.556,93.281,35.046,84.717,33.597z" fill="#bddeff" transform="scale(1)"></path>
    </g></g>
    </svg>
  )
}
