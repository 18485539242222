import React, { useContext, useRef } from 'react';
import { ReducerContext } from 'app';

import TaskTags from './tags/tags';
import TaskDueDate from './badges/due-date';
import { isMyProjectTask, getHoursMinsStr } from 'components/utils/utils';

export default function Task({ itemData }) {

  const { state, dispatch } = useContext(ReducerContext);

  const taskRef = useRef();

  let taskclass = itemData.completed_at ? itemData.confirmed_at ? "completed confirmed" : itemData.completed_at ? "completed " : "notcomplete" : itemData.completed_at ? "completed" : "notcomplete";
  //taskclass += (itemData.account_manager === state.user._id) ? " readytoinvoice " : "";
  taskclass += (!isMyProjectTask(itemData)) ? " alpha " : "";
  // taskclass += (itemData.account_manager !== state.user._id && itemData.creator?._id !== state.user._id && !itemData.users?.includes(state.user._id)) ? " alpha" : "";
  taskclass += (itemData.invoiceable) ? " invoiceable " : "";
  taskclass += (itemData.invoice_id) ? " invoiced " : "";

  return (
    <div className={"task " + taskclass} ref={taskRef} >

      <TaskTags task={itemData} />

      <div className='task__text' onClick={e => { dispatch({ type: "setActiveProjectTaskID", payload: itemData._id }) }} >
        {itemData?.name}<br />{getHoursMinsStr(itemData).str}
      </div>

      {
        itemData.due_date && // refactor to account for other badges
        <div className="task__badges">
          <TaskDueDate task={itemData} />
        </div>
      }

    </div>
  )
}