import React, { useContext } from 'react';
import { ProjectsContext, ReducerContext } from 'app';

import { ButtonGlow } from 'components/utils/buttons/buttons';
import List from 'components/utils/list/list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faPlus, faSquare } from '@fortawesome/free-solid-svg-icons';
import { dateFormat, daysoverdue, dateDiffDays, currencyFormat, getHoursMinsStr } from 'components/utils/utils';

function MenuInvoice({ itemData }) {

  const { state, dispatch } = useContext(ReducerContext);


  const setUserProjectOrInvoice = async () => {

    if (!itemData.total_amount) {
      //is a project
      if (itemData._id !== state.activeProject) {
        dispatch({ type: "setActiveProjectID", payload: itemData._id })
      }
      else {

      }
    }
    else {

      if (itemData._id !== state.activeInvoice) {
        dispatch({ type: "setActiveInvoiceID", payload: itemData._id })
      }
      else {

      }
    }

  }

  let customer = itemData.customer ? itemData.customer : itemData.client;

  let due_date = itemData?.due_date ? (dateFormat(itemData.due_date, "nice")) : dateFormat(itemData?.due_date, "nice");

  let overdue = daysoverdue(due_date, false);

  let color = "green";

  if (overdue > 0) {
    color = "orange";
  }

  if (!itemData.total_amount) {
    color = "red";
  }
  return (
    <div className={`invoice ${itemData._id === state.activeInvoiceID ? "active" : ""}`} onClick={setUserProjectOrInvoice}>
      <div className={`${itemData.next_repeat_date ? 'is_repeat' : ''} invoice__data`}>
        <div className='icons'>
          <span style={{ color: color }}><FontAwesomeIcon icon={faSquare} /></span>
        </div>
        <div className='invoice__name'>
          <b>{customer?.name}</b><br />
          {
            itemData.total_amount &&
            <>
              {currencyFormat(itemData.total_amount)} {itemData.invoice_lines.length} Items<br /><div className='nextdue_date'>
                {dateFormat(itemData?.raised_date, "nice")} -  {dateFormat(itemData?.due_date, "nice")} ({daysoverdue(itemData?.due_date)} days  overdue)</div>
            </>
          }
          {
            !itemData.total_amount &&
            <>
              <b>{itemData.project_number} - {itemData.name}</b><br />
              {getHoursMinsStr(itemData, true).str}
            </>
          }

        </div>

      </div>
    </div>
  )
}

export default function MenuInvoices() {
  const { invoices, invoiceables } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [thisState, setThisState] = React.useState({});

  let invoicesArr = invoices || [];
  let invoiceablesArr = invoiceables || [];

  
  let filter = state.filter;

  if (filter) {
    let keyword = filter.toLowerCase();
    invoicesArr = invoicesArr.filter(function (invoice) {
      
      let clientname = invoice.client?.name.toLowerCase() || "";

      return  clientname.indexOf(keyword) > -1;
    });

    invoiceablesArr = invoiceablesArr.filter(function (invoiceable) {
      
      let clientname = invoiceable.client?.name.toLowerCase() || "";
      return  clientname.indexOf(keyword) > -1;
    });

  }

  let overdueInvoices = invoicesArr?.filter(function (invoice) {

    let due_date = invoice?.due_date ? (dateFormat(invoice.due_date, "nice")) : dateFormat(invoice?.due_date, "nice");
    let overdue = daysoverdue(due_date, false);
    return (overdue > 0);
  });

  overdueInvoices = [];

  let underdueInvoices = invoicesArr?.filter(function (invoice) {
    let due_date = invoice?.due_date ? (dateFormat(invoice.due_date, "nice")) : dateFormat(invoice?.due_date, "nice");
    let overdue = daysoverdue(due_date, false);

    return (overdue <= 0);
  });

  let notInvoiced = invoicesArr?.filter(function (invoice) {

    return invoice.total_amount <= 0 ? invoice : {};
    //return (project.creator?._id === state.user._id || owners.includes(state.user._id)) || (project.incompletetaskcount > 0 || project.unconfirmedtaskcount > 0 || project.readyforinvoicetaskcount > 0);
  });

  return (
    <div className={`invoices ${thisState.invoicesHidden ? "hidden" : ""} `}>
      <div className='header' >
        <h2 onClick={e => { if (thisState.invoicesHidden) { setThisState({ ...thisState, invoicesHidden: false }) } else { setThisState({ ...thisState, invoicesHidden: true }) } }}> {invoicesArr.length} Invoices <FontAwesomeIcon icon={thisState.invoicesHidden ? faChevronUp : faChevronDown} /></h2>
        <ButtonGlow onClick={() => { dispatch({ type: 'menuInvoicesShow', payload: true }) }} icon={faPlus} fontSize='1.5rem' />
      </div>
      <div>
        <div className='hide'>
          {state.fetchingData &&
            <>
              <b style={{ color: "#fff" }}>Loading Invoices</b>
            </>
          }
          Overdue Invoices ({overdueInvoices.length})
          <List
            classes="hide invoices__list"
            ids="list--invoices"
            elements={overdueInvoices}
            itemtemplate={MenuInvoice}
          />

          Not Yet Due Invoices ({underdueInvoices.length})
          <List
            classes="hide invoices__list"
            ids="list--invoices"
            elements={underdueInvoices}
            itemtemplate={MenuInvoice}
          />
        </div>
        Not Invoiced ({invoiceablesArr.length})
        <List
          classes="invoices__list"
          ids="list--invoices"
          elements={invoiceablesArr}
          itemtemplate={MenuInvoice}
        />
      </div>
    </div>
  )
}