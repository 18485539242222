import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare,faFileInvoice, faCircleXmark } from '@fortawesome/free-solid-svg-icons';


export default function Popup(props) {

	
	var zIndex = props.zIndex ? props.zIndex : 9999999;
	var className = props.className ? props.className : "";

		return (
			<div id="popup-container-main" style={{zIndex}} className={className}>
				<div className="hiding_layout"  onClick={props.hide} ></div>
				<div className="popup_main_content">
					<div className="popup_contents_outer">
						<button className="close_popup" onClick={props.hide}><FontAwesomeIcon style={{ color: "#ff0000" }} icon={ faCircleXmark }/></button>
						<div className="popup_contents_inner">
							{props.children}
						</div>
					</div>
				</div>
			</div>

		)
	
}