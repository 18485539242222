import React, { useContext, useState, useRef } from "react";
import { ReducerContext } from "app";

import CardHeader from "./card-header/card-header";
import Task from '../task/task'
import List from 'components/utils/list/list';
import AddTask from "./add-task/add-task";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

export const OptionsContext = React.createContext();

export default function Card(props) {

  const { dispatch } = useContext(ReducerContext);
  const [cardState] = useState({});
  const optionsRef = useRef(null);

  let project = props.project;
  let card = props.card;

  if (card) {

    let tasks = card.tasks || [];
    tasks = tasks.map(task => ({ ...task, account_manager: project.account_manager }))

    tasks.sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date) || new Date(a.confirmed_at) - new Date(b.confirmed_at);
      //return new Date(a.confirmed_at) - new Date(b.confirmed_at);
    });


    function toggleOptions() {
      if (cardState.cardOptions?.data)
        dispatch({ card: 'setCardOptions', payload: { params: null, data: null } })

      else {
        const params = {};

        let optionsRect = optionsRef.current.getBoundingClientRect();
        params.x = optionsRect.left + window.scrollX + optionsRef.current.scrollLeft - 166;
        params.y = optionsRect.top + window.scrollY + optionsRef.current.scrollTop + 46;

        dispatch(
          {
            type: 'setCardOptions',
            payload:
            {
              params: params,
              data: card
            }
          })
      }
    }


    return (
      <div className="card" id={card.name}>
        <CardHeader card={card} />

        <div className='options' onClick={toggleOptions} ref={optionsRef}>
          <FontAwesomeIcon icon={faEllipsis} />
        </div>

        <List
          classes='card__list'
          ids={`list--${card._id}`}
          elements={tasks}
          itemtemplate={Task}
        />


        <AddTask card={card} />
      </div>
    )
  }
}