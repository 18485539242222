import { useContext } from 'react'
import { ProjectsContext, ReducerContext } from 'app'

import getTextColor from 'utils/getTextColor';
import List from 'components/utils/list/list'

export default function TaskTags({ task })
{
  const { activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);

  const filteredTags = task.tags;

  function TaskTag({ itemData }){
    const style = 
    {
      backgroundColor: itemData.color,
      color: getTextColor(itemData.color)
    };

    if (state.tagsNameShown)
      return <li className='task__tag' style={ style }>{ itemData.name }</li>

    else
      return <li className='task__tag task__tag--hidden' style={ style }></li>
  }

  if (filteredTags.length < 1)
    return null;

  return (
    <List
      classes='task__tags'
      ids={`list--${task._id}:display-tags`}
      elements={ filteredTags }
      ListItem={ TaskTag }
      onClick={ () => {dispatch({ type: 'tagsNameShown', payload: !state.tagsNameShown })} }
    />
  )
}